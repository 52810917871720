import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  UncontrolledTooltip,
  Input,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import { useSpring, animated } from "react-spring";
import { FunnelIcon, ReUploadIcon } from "../../assets/icons/svg";
import { Link } from "react-router-dom";
import loadingAnimation from "../../../src/images/puff.svg";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheetUpload from "../../components/Modal/ModalBody/timeSheetUpload";
const filterByName = (name, dataSet) => {
  if (!name) {
    return { filtered: dataSet, lengthofSearch: dataSet.length };
  }

  const lowerCaseName = name.toLowerCase();
  const filtered = dataSet.filter((item) => {
    const searchableString =
      `${item.locumName} ${item.hospitalName}`.toLowerCase();
    return searchableString.includes(lowerCaseName);
  });

  return { filtered, lengthofSearch: filtered.length };
};

const EmployeePayrollWidgetRejected = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const MexxarAPI = HOSTMexxar;

  const getRejectedTimesheet = () => {
    setLoading(true);
    axios
      .get(MexxarAPI + "workspace/payroll?rs=DECLINE")
      .then((res) => {
        setRows(res.data.body);
        setLoading(false);
        if (res.data.status === "success") {
          setRows(res.data.body);
          setLoading(false);
        } else if (res.data.status === "failed") {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  useEffect(() => {
    getRejectedTimesheet();
    return () => {};
  }, []);

  const sortedRows = rows.sort(
    (a, b) => new Date(b.dateOfShift) - new Date(a.dateOfShift)
  );
  const filteredData = filterByName(searchQuery, sortedRows);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = (reason = "") => {
    if (!modalOpen) {
      setRejectReason(reason);
    }
    setModalOpen(!modalOpen);
  };

  const toggleShowTimeModal = (data) => {
    setData(data.booking);
    setShowTimeModal(!showTimeModal);
  };

  return (
    <div>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3"} />
        <Label className={"mr-3"}>{rows.length} Timesheets</Label>
        <Link to="/payroll/mobileTimesheetScan"></Link>
        <Button className="btn bg-dark-lt text-dark" disabled={true}>
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{ backgroundColor: "transparent" }}
          onChange={handleSearchOnChange}
        />
      </div>

      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id/Client Id/ Name
      </UncontrolledTooltip>

      {!loading ? (
        filteredData.lengthofSearch > 0 ? (
          filteredData.filtered.map((row, id) => (
            <animated.div style={animationProps} key={id}>
              <div className="card mb-2" key={id}>
                <div className="card-body">
                  <div className="flex flex-row d-flex justify-content-between">
                    <div className="d-flex" style={{ width: "250px" }}>
                      <div className="d-flex align-items-center mr-3">
                        <span className="mx-2">
                          <b className="badge badge-circle sm text-primary"></b>
                        </span>
                      </div>
                      <div>
                        <div>{row.locumName}</div>
                        <div className="item-mail text-muted h-1x d-none d-sm-block">
                          <small>
                            Shift Date: {moment(row.dateOfShift).format("llll")}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="textCenter-Custom">
                      <div>Booking Reference</div>
                      <div className="text-muted">{row.bookingRef}</div>
                    </div>
                    <div className="textCenter-Custom">
                      <div>Hospital Name</div>
                      <div className="text-muted">{row.hospitalName}</div>
                    </div>

                    <div>
                      <Button
                        color="success"
                        className="mr-2"
                        onClick={() => toggleModal(row.rejectReason)}
                      >
                        Reason
                      </Button>
                      <Button
                        color="primary"
                        className="mr-2"
                        onClick={() => {
                          toggleShowTimeModal(row);
                        }}
                      >
                        <ReUploadIcon />
                        <span className="mx-1">Reupload</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          ))
        ) : (
          <NoSearchResults />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>TimeSheet Reject Reason</ModalHeader>
        <ModalBody>
          <p>{rejectReason || "No reason provided."}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <ModalComponent
        show={showTimeModal}
        header="Upload Timesheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetUpload
          data={data}
          closeModal={() => setShowTimeModal(false)}
          // getCandidates={refreshPage}
        ></TimeSheetUpload>
      </ModalComponent>
    </div>
  );
};

export default EmployeePayrollWidgetRejected;
